import React from "react";
import Layout from "../components/layout";
import AddressAndTransport from "../components/AddressAndTransport";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import ContactForm from "../components/contactForm";
import { graphql, useStaticQuery } from "gatsby";
import { parseNestedHTML } from "../Utils";
import Slider from "react-slick";
import HTMLReactParser from "html-react-parser";
import NinjaStarSVG from "../components/svg/NinjaStarSVG";
import SafeHtmlParser from "../components/safe-html-parser";
const settings = {
	dots: true,
	dotsClass: "slick-dots slick-thumb",
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	autoplay: true,
};

const MarketingPageTemplate = ({ data: { wpMarketingPage, site } }) => {
	const MarketingPage = wpMarketingPage.marketingPageFields;
	const seoFields = wpMarketingPage.seoFields;
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${site.siteMetadata.siteUrl}`,
					id: `${site.siteMetadata.siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Landing",
				item: {
					url: `${site.siteMetadata.siteUrl}/landing`,
					id: `${site.siteMetadata.siteUrl}/landing`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${wpMarketingPage?.title}`,
				item: {
					url: `${site.siteMetadata.siteUrl}/landing/${wpMarketingPage?.slug}`,
					id: `${site.siteMetadata.siteUrl}/landing/${wpMarketingPage?.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/landing/${wpMarketingPage?.slug}`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>

			<section className="position-relative ">
				<div className="position-relative hero-carousal">
					<div
						style={{
							zIndex: 1,
							background:
								"linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)",
							borderTop: "1px solid #FFF",
							borderBottom: "1px solid #FFF",
						}}
						className="position-absolute h-100 w-100"
					>
						<Container className="h-100">
							<Row className="align-items-center h-100">
								<Col>
									<div className="h-100 ">
										<div className="stylishText text-uppercase  ">
											<SafeHtmlParser
												htmlContent={MarketingPage.heroSection.title}
											/>
										</div>
										<Button
											target="_blank"
											rel="noreferrer"
											href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
											className="text-white text-uppercase w-100 w-md-auto  fs-5 px-4 py-2"
										>
											{MarketingPage.heroSection.btnText}
										</Button>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
					<div className="slider-container">
						<Slider {...settings}>
							{MarketingPage.heroSection.image.map((slider, i) => (
								<div key={i}>
									<GatsbyImage
										className="images-height"
										image={
											slider.image?.node.localFile?.childImageSharp
												?.gatsbyImageData
										}
										style={{
											objectFit: "cover",
											width: "100%",
										}}
										alt={slider.image?.node?.altText}
									/>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</section>
			<section className="py-5 pt-lg-7">
				<Container>
					<Row>
						<Col>
							<h2 className="display-5 pb-3 text-uppercase">
								{MarketingPage.summerHolidaysWithNinjaFun.title}
							</h2>

							<div>
								<SafeHtmlParser
									htmlContent={MarketingPage.summerHolidaysWithNinjaFun.body}
								/>
							</div>

							<div className="w-100 py-5">
								<GatsbyImage
									image={
										MarketingPage.supervision.image?.node.localFile
											?.childImageSharp?.gatsbyImageData
									}
									style={{
										objectFit: "cover",
										width: "100%",
									}}
									alt={MarketingPage.supervision.image?.node?.altText}
								/>
							</div>
							<h2 className="display-5 pb-3 text-uppercase">
								{MarketingPage.supervision.title}
							</h2>

							<div>
								<SafeHtmlParser htmlContent={MarketingPage.supervision.body} />
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-5 pb-lg-7">
				<Container>
					<Row className="g-5">
						<Col lg={6}>
							<div
								className="p-3 h-100"
								style={{ backgroundColor: "#181819", borderRadius: 8 }}
							>
								<GatsbyImage
									image={
										MarketingPage.unleashYourInnerNinja.image?.node.localFile
											?.childImageSharp?.gatsbyImageData
									}
									style={{
										borderRadius: 12,
										height: "250px",
									}}
									alt={MarketingPage.unleashYourInnerNinja.image?.node?.altText}
								/>
								<h3 className=" mt-3 pt-2 text-uppercase">
									{MarketingPage.unleashYourInnerNinja.title}
								</h3>
								<p className="text-white pt-2 pb-0 fs-5 ">
									{MarketingPage.unleashYourInnerNinja.body}
								</p>
							</div>
						</Col>
						<Col lg={6}>
							<div
								className="p-3 h-100"
								style={{ backgroundColor: "#181819", borderRadius: 8 }}
							>
								<GatsbyImage
									image={
										MarketingPage.cafeToRestAndRecoup.image?.node.localFile
											?.childImageSharp?.gatsbyImageData
									}
									style={{
										borderRadius: 12,
										height: "250px",
									}}
									alt={MarketingPage.cafeToRestAndRecoup.image?.node?.altText}
								/>
								<h3 className=" mt-3 pt-2 text-uppercase">
									{MarketingPage.cafeToRestAndRecoup.title}
								</h3>
								<p className="text-white pt-2 pb-0 fs-5 ">
									{MarketingPage.cafeToRestAndRecoup.body}
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-5 pb-lg-7">
				<Container>
					<Row>
						<Col>
							<Row className=" g-3">
								{MarketingPage.services.map(
									({ icon, title, subheading }, i) => (
										<Col lg={3} md={6}>
											<Stack
												className="justify-content-start text-center h-100 align-items-center p-4"
												key={i}
												style={{ backgroundColor: "#181819", borderRadius: 8 }}
											>
												<div
													className=" d-inline-block mb-4"
													style={{
														minWidth: "70px",
														width: "70px",
														maxHeight: "70px",
													}}
												>
													<img
														src={icon?.node?.sourceUrl}
														alt={icon?.node?.altText}
														className="w-100 mh-100"
													/>
												</div>

												<h3>{title}</h3>
												<p className="pb-0 mb-0">{subheading}</p>
											</Stack>
										</Col>
									)
								)}
							</Row>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="pb-5 pb-lg-7">
				<Container>
					<Row className="justify-content-center">
						<Col lg={8}>
							<Stack gap={3} className="align-items-center text-center">
								<h2 className="display-5 pb-0 text-uppercase">
									{MarketingPage.weAreOpenThroughoutTheHolidays.title}
								</h2>

								<div>
									<SafeHtmlParser
										htmlContent={
											MarketingPage.weAreOpenThroughoutTheHolidays.body
										}
									/>
								</div>
								<p className="text-white">
									{MarketingPage.weAreOpenThroughoutTheHolidays.subheading}
								</p>
								<Button
									target="_blank"
									rel="noreferrer"
									href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
									className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
								>
									{MarketingPage.weAreOpenThroughoutTheHolidays.btnText}
								</Button>
							</Stack>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="pb-5 pb-lg-7">
				<AddressAndTransport
					address={MarketingPage.address}
					vehiclesStatus={MarketingPage.vehicleStatus}
				/>
			</section>
		</Layout>
	);
};

export default MarketingPageTemplate;

export const pageQuery = graphql`
	query MarketingPageById($id: String!) {
		wpMarketingPage(id: { eq: $id }) {
			seoFields {
				localBusinessSchema
				metaDescription
				opengraphDescription
				opengraphTitle
				title
				image {
					node {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
			marketingPageFields {
				address {
					btnText
					location {
						lat
						lng
					}
					title
				}
				heroSection {
					btnText
					title
					image {
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
				cafeToRestAndRecoup {
					body
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					title
				}
				services {
					subheading
					title
					icon {
						node {
							sourceUrl
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				summerHolidaysWithNinjaFun {
					body
					btnText
					title
				}
				supervision {
					body
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					title
				}
				unleashYourInnerNinja {
					body
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					title
				}
				vehicleStatus {
					body
					icon {
						node {
							sourceUrl
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					title
				}
				weAreOpenThroughoutTheHolidays {
					body
					btnText
					subheading
					title
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
